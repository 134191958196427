var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"8bfIbSmusCCk3fQws6w1J"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

import {
  getEnvironment,
  getSentryDSN,
  getSentryTracesSampleRate,
  getAppServer,
} from 'lib/config';

const appServerHostname = new URL(getAppServer()).hostname;

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: getSentryDSN() || '',
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: getSentryTracesSampleRate(),

    // This enables automatic instrumentation (highly recommended), but is not
    // necessary for purely manual usage
    integrations: [
      new TracingIntegrations.BrowserTracing({
        tracingOrigins: [appServerHostname],
      }),
    ],

    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps

    environment: getEnvironment(),
  });
}
